.heroWrapper {
    position: relative;
    text-align: center;
}

.heroImage {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;
}

.textContainer {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255); /* Semi-transparent white */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    width: 60%;
    height: fit-content;
}

.title {
    color: #212529;
    font-size: 2rem;
}
.logo {
    width: 250px; /* Example width - adjust as needed */
    height: auto; /* This will maintain the aspect ratio of the logo */
    /* Add any additional styling as needed */
}

.description {
    color: #333;
    font-size: 1rem;
}

.button {
    display: inline-block;
    background-color: #DAA520;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    border: none;
    margin-top: 10px;
}
@media (max-width: 600px) {
    .textContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255); /* Semi-transparent white */
        padding: 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        width: 65%;
    }
}