.testimonialsContainer {
    padding: 20px;
    text-align: center;
}

.testimonial {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.testimonialText {
    font-style: italic;
    color: #333;
}

.author {
    margin-top: 10px;
    font-weight: bold;
    color: #555;
}

/* Add any additional styling as needed */
