.HomePage {
    color: #4A2E2F;
    text-align: center;     
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.NavBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    background: #ffffff; /* Or any other color that matches your design */
}

.Logo {
    width: 150px; /* Adjust according to your preference */
    height: auto;
}

.LoginButton, .LogoutButton {
    background-color: #DAA520;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px;
}

.MainContent {
    flex-grow: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Ensure enough space for footer on mobile */
    margin-bottom: 60px; /* Adjust this value based on the footer height */
}

/* Media queries for mobile responsiveness */
@media (max-width: 600px) {
    .NavBar {
        flex-direction: row;
        justify-content: space-between;
    }

    .Logo {
        width: 150px; /* Smaller logo for mobile devices */
    }

    .LoginButton, .LogoutButton {
        padding: 8px 16px; /* Smaller padding on mobile */
        font-size: 14px; /* Smaller font size on mobile */
        width: auto; /* Adjust width as needed or use max-width to constrain it */
        margin: 0.5rem;
    }

    .MainContent {
        padding: 1rem; /* Add padding on the sides for smaller screens */
    }
}

